<template>
  <div class="products-search">
    <template v-if="products.length > 0">
      <ul class="products-search__list row">
        <template>
          <div
            v-for="(product, index) in products"
            class="col-12 col-md-6"
            :key="product.id"
          >
            <li
              :class="[
                product.responded ? 'products-search__list-item--disabled' : '',
                product.responded && product.found === 'Found'
                  ? 'bg-success'
                  : product.responded && product.found === 'Not Found'
                  ? 'bg-danger'
                  : ''
              ]"
              class="products-search__list-item"
              v-on:click="toggleProduct(index)"
            >
              <div class="products-search__image">
                <img :src="product.img" alt="product.id" />
              </div>
              <div class="products-search__details">
                <span>{{ product.amount }}x {{ product.name }}</span>
                <span v-if="regionCheckUSorOther === true"
                  >$ {{ product.unitPrice }} / unit</span
                >
                <span v-else>PKR {{ product.unitPrice }} / unit</span>
              </div>
              <div
                v-on:click.stop.prevent="toggleProduct(index)"
                class="products-search__check-box"
              >
                <template v-if="product.responded">
                  <b-form-checkbox
                    v-model="product.responded"
                  ></b-form-checkbox>
                </template>
                <template v-else>
                  <b-form-checkbox v-model="product.checked"></b-form-checkbox>
                </template>
              </div>
            </li>
          </div>
        </template>
      </ul>
      <div class="products-search__found-not-found" v-show="!completed">
        <button class="baskyt-btn" @click="productsFound(true)">Found</button>
        <button
          class="baskyt-btn baskyt-btn--white"
          @click="productsFound(false)"
        >
          Not Found
        </button>
      </div>
    </template>

    <template v-if="loading === true">
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle mx-4"></b-spinner>
        <strong>Processing...</strong>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getStoreId } from "@/core/services/jwt.service";
// import BaskytModal from "@/view/components/common/BaskytModal";
import {
  FIND_PRODUCTS,
  GET_ORDER_STATUS_ACTIVE
} from "@/core/services/store/orders.module";

export default {
  // components: { BaskytModal },
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}products-search`,

  data() {
    return {
      loading: false,
      products: [],
      completed: false,
      orderId: null,
      testCheck: null,
      squadId: null
    };
  },
  async mounted() {
    const regionChecker = this.regionCheckUSorOther;
    const res =
      this.$store.getters.getActiveOrder(this.$route.params.id) ||
      this.$store.getters.getActiveSquadOrder(this.$route.params.id);
    if (!res) {
      this.$router.push(`/order/${this.$route.params.id}`);
    } else {
      // eslint-disable-next-line no-underscore-dangle
      this.squadId = res._id;
      // eslint-disable-next-line no-underscore-dangle
      this.orderId = res._id;
      let store = ""; //  p.productImage  check later after api update
      if (res.inviteId) {
        store = getStoreId();
        const { product, deliveryInfo } = res;
        if(res.productsFound !== "Found")
        res.productsFound = "Pending";
        const products = [
          {
            // eslint-disable-next-line no-underscore-dangle
            id: product._id,
            img: product.images[0],
            name: product.name,
            unitPrice: product.price,
            found: res.productsFound || "Pending",
            amount: deliveryInfo.reduce((acc, it) => acc + it.quantity, 0),
            responded: !!(res.productsFound && res.productsFound !== "Pending"),
            checked: res.productsFound === "Pending" ? null : false
          }
        ];
        this.products = products;
        this.productsFound();
      } else if (!res.inviteId) {
        if (regionChecker) {
          store = res.store;

          const products = res.products.map(p => ({
            // eslint-disable-next-line no-underscore-dangle
            id: p.productId._id,
            img: p.productId.images[0],
            name: p.productId.name,
            unitPrice: p.price,
            found: p.found,
            amount: p.quantity,
            responded: p.found === "Found" || p.found === "Not Found",
            checked: p.found === "Pending" ? null : false
          }));
          this.products = products;
          await this.productsFound();
        } else {
          const { stores } = res;
          store = stores.find(s => s.store === getStoreId());
          const products = store.products.map(p => ({
            id: !p.isDeal ? p.product : p.deal,
            img: p.image,
            name: p.name,
            unitPrice: p.cost,
            found: false,
            amount: p.quantity,
            responded: p.found === "Found" || p.found === "Not Found",
            checked: p.found === "Pending" ? null : false
          }));
          this.products = products;
          await this.productsFound();
        }
      }
    }
    if (this.products.length < 1) {
      this.$router.push(`/order/${this.$route.params.id}`);
    }
  },
  methods: {
    ...mapActions({
      findProducts: FIND_PRODUCTS,
      activeOrders: GET_ORDER_STATUS_ACTIVE
    }),
    async productsFound(found = true) {
      this.loading = true;
      let completed = true;
      const updateProducts = [];
      const tempProducts = this.products.map(original => {
        const item = original;
        if (!item.responded && item.checked !== null && item.checked) {
          item.found = found;
          item.responded = true;
          updateProducts.push(item);
        }

        if (!item.responded) completed = false;
        return item;
      });
      if (updateProducts.length > 0) {
        const request = {
          products: updateProducts.reduce((acc, p) => {
            acc[p.id] = p.found ? "Found" : "Not Found";
            return acc;
          }, {}),
          orderId: this.orderId,
          storeId: getStoreId(),
          squadId: this.squadId
        };

        const updated = await this.findProducts(request);

        if (updated) {
          await this.activeOrders();
          this.products = tempProducts;
          this.completed = completed;
        } else {
          console.log("error");
        }
      }
      this.completed = completed;
      this.loading = false;
      // if (completed) {
      //   setTimeout(() => {
      //     this.$refs.productsPicked.show();
      //     setTimeout(() => {
      //       this.$refs.productsPicked.hide();
      //     }, 1500);
      //   }, 100);
      // }
    },
    modalClosed() {
      this.$router.push("/order-status/in-progress");
    },
    toggleProduct(index) {
      this.products[index].checked = !this.products[index].checked;
    },
    test(index) {
      this.products[index].checked = !this.products[index].checked;
    }
  },
  computed: {
    ...mapState({
      regionCheckUSorOther: state => state.regions.region
    })
  }
};
</script>
<style lang="scss" scoped></style>
